/* eslint-disable import/no-unresolved */
/* eslint-disable import/order */

import { useCallback, useEffect, useMemo, useState } from 'react';

import { useInterval } from 'react-use';

import { useAuth0 } from '@/context/auth';
import { useToken } from '@/hooks/useToken';
import { CHAT_API_URL, LIVE_CHAT_MAX_MESSAGES, LIVE_CHAT_POLLINGINTERVALMS } from '@/lib/constants';

import { ChatMessage } from './types';
import {
  filterOutOptimisticMessages,
  makeNonceGenerator,
  normalizeMessageData,
  resolveTailMessageId,
  resolveTopMessageId,
} from './utils';

const generateNonce = makeNonceGenerator();

export interface UseChatMessagesOptions {
  pollingIntervalMs?: number;
  onFirstLoad?: () => void;
  onMessageSent?: () => void;
  onReplySent?: () => void;
  onSendMessageError?: (response: Response) => void;
}

export function useChatMessages(
  roomId: string,
  { pollingIntervalMs = LIVE_CHAT_POLLINGINTERVALMS, onFirstLoad, onSendMessageError }: UseChatMessagesOptions = {},
) {
  const { user } = useAuth0();
  const { getToken } = useToken();

  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [loadingTopMessages, setLoadingTopMessages] = useState(false);
  const topMessageId = useMemo(() => resolveTopMessageId(messages), [messages]);
  const tailMessageId = useMemo(() => resolveTailMessageId(messages), [messages]);

  const handleFirstLoad = useCallback(() => {
    if (typeof onFirstLoad === 'function') {
      // FIXME: Remove this timeout and make the "first load" detection
      // more reliable
      setTimeout(() => {
        onFirstLoad();
      }, 1000);
    }
  }, [onFirstLoad]);

  // Gets previous messages when the user scrolls up
  const fetchTopMessages = useCallback(
    async (existingMessages: any[], customTopMessageId?: number) => {
      /* we only want to attempt to fetch top messages
         if we are below the max we are allowing in state */
      if (messages.length >= LIVE_CHAT_MAX_MESSAGES) return;
      // set loading state for top message loading component
      setLoadingTopMessages(true);
      const response = await fetch(
        `${CHAT_API_URL}/messages?` +
          new URLSearchParams({
            room_id: roomId,
            id: String(customTopMessageId || topMessageId),
            end: 'top',
            nonce: generateNonce(),
          }),
      );
      const newTopMessages: ChatMessage[] = await response.json();
      if (!newTopMessages?.length) {
        // end loading state for top message loading component
        setLoadingTopMessages(false);
        return;
      }

      const normalizedMessages = normalizeMessageData([...existingMessages, ...newTopMessages]);

      // FIXME: This looks hacky af, reconsider
      const a = [...existingMessages, ...newTopMessages];
      if (a && a.length && a[a.length - 1].replay_id) {
        // If last msg is reply then get more messages
        fetchTopMessages(a, newTopMessages[newTopMessages.length - 1].id);
      }
      // end loading state for top message loading component
      setMessages(normalizedMessages);
      setLoadingTopMessages(false);
      return normalizedMessages;
    },
    [messages, roomId, topMessageId],
  );

  const fetchMessages = useCallback(
    async (updatedRoomId) => {
      const promise = await fetch(
        `${CHAT_API_URL}/messages?` +
          new URLSearchParams({
            room_id: updatedRoomId,
            nonce: generateNonce(),
          }),
      );
      if (!promise.ok) {
        onSendMessageError?.(promise);
        return;
      }
      const newMessages: ChatMessage[] = await promise.json();
      if (newMessages?.length) {
        const normalizedMessages = normalizeMessageData(newMessages);

        // FIXME: Reconsider implementation
        if (newMessages[newMessages.length - 1].replay_id) {
          handleFirstLoad();
          return fetchTopMessages(newMessages, newMessages[0].id);
        }
        setMessages(normalizedMessages.slice(0, LIVE_CHAT_MAX_MESSAGES));
      }

      handleFirstLoad();
    },
    [fetchTopMessages, handleFirstLoad, onSendMessageError],
  );

  // Gets tail messages, this function runs at every N milliseconds
  // based on pollingIntervalMs (defaults to 2000, i.e., 2 seconds)
  // by the useInterval hook below.
  const fetchTailMessages = useCallback(async () => {
    if (!Array.isArray(messages)) return;
    const id = tailMessageId ? String(tailMessageId) : '1';
    const response = await fetch(
      `${CHAT_API_URL}/messages?` +
        new URLSearchParams({
          room_id: roomId,
          id: id,
          end: 'tail',
          nonce: generateNonce(),
        }),
    );

    const newTailMessages: ChatMessage[] = await response.json();
    if (!newTailMessages) return;

    setMessages(
      filterOutOptimisticMessages(normalizeMessageData([...newTailMessages, ...messages])).slice(
        0,
        LIVE_CHAT_MAX_MESSAGES,
      ),
    );
  }, [messages, roomId, tailMessageId]);

  // Send a new message
  const sendMessage = useCallback(
    async (messageText: string, isReply?, mainMessageId?) => {
      if (!messageText) return;

      const messageData = {
        room_id: roomId,
        replay_id: isReply ? mainMessageId : 0,
        content: messageText,
        user_image: user?.picture,
        username: user?.name,
      };

      const requestBody = {
        ...messageData,
        token: await getToken(),
      };

      const response = await fetch(`${CHAT_API_URL}/messages`, {
        method: 'POST',
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        onSendMessageError?.(response);
      }

      // Note: The response we get here doesn't contain the message we just sent
      // and we can't obtain its ID from here, so the only option is to immediate
      // manual refetch.
      fetchTailMessages();
    },
    [fetchTailMessages, getToken, onSendMessageError, roomId, user?.name, user?.picture],
  );

  useEffect(() => {
    fetchMessages(roomId);
    // NOTE: This rule is disabled intentionally. In the current implementation,
    // the callback dependency chain makes this function run every time
    // `topMessageId` is updated and not only on mount. After reconsidering
    // the `fetchMessages` implementation, this will be more reliable and
    // have all the necessary deps specified.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomId]);

  /* This is called when the scroll to bottom button 
     is pressed. The UI performs better this way, rather 
     than trying to keep up with the growing message state */
  const resetMessages = useCallback(() => {
    setMessages((prev) => {
      return [...prev.slice(0, 50)];
    });
  }, []);

  useInterval(() => {
    return fetchTailMessages();
  }, pollingIntervalMs);

  return {
    messages,
    tailMessageId,
    topMessageId,
    fetchMessages,
    fetchTopMessages,
    loadingTopMessages,
    fetchTailMessages,
    sendMessage,
    resetMessages,
  };
}
